import airtelLogo from '../../public/images/airtel-logo.svg';
import airtelWhiteLogo from '../../public/images/airtel-white.svg';
import airtelLandingLogo from '../../public/images/airtel_landing_img.svg';
import pnfWhiteLogo from '../../public/images/logo-white.svg';
import pnfLogo from '../../public/images/logom-pnf.svg';
import mtnLandingLogo from '../../public/images/mtn_landing_img.svg';
import mtnLogo from '../../public/images/nav-partner-logo.svg';
import pnfLandingLogo from '../../public/images/pnf-landing-img.webp';
import ukPnfLandingLogo from '../../public/images/uk-hero.webp';

export const partnerCode = process.env.NEXT_PUBLIC_PARTNER_CODE || 'PNFNG';

export const isMTN = process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG';
export const isPNF = process.env.NEXT_PUBLIC_PARTNER_CODE === 'PNFNG';
export const isAIR = process.env.NEXT_PUBLIC_PARTNER_CODE === 'AIRNG';
export const isUK = process.env.NEXT_PUBLIC_PARTNER_CODE === 'PNFUK';

export const getFaviconPathByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return '/picknfix-favicon';
    case 'MTNNG':
      return '/mtn-favicon';
    case 'AIRNG':
      return '/airtel-favicon';
    default:
      return '/picknfix-favicon';
  }
};

export const getTitleByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'Pick N Fix';
    case 'MTNNG':
      return 'My MTN Pick N Fix';
    case 'AIRNG':
      return 'Airtel Pick N Fix';
    default:
      return 'Pick N Fix';
  }
};

export const getPartnerNameByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'PicknFix';
    case 'MTNNG':
      return 'MTN';
    case 'AIRNG':
      return 'Airtel';
    default:
      return 'PicknFix';
  }
};

export const getFontsByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return `'Lota Grotesque', sans-serif`;
    case 'MTNNG':
      return `'MTNBrighterSans-ExtraBold', sans-serif`;
    case 'AIRNG':
      return `'Lota Grotesque', sans-serif`;
    default:
      return `'Lota Grotesque', sans-serif`;
  }
};

export const fontFamily = getFontsByPartner();

export const getThemeByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return '#57AFE9';
    case 'MTNNG':
      return '#ffcb05';
    case 'AIRNG':
      return '#F22121E5';
    default:
      return '#57AFE9';
  }
};
export const getChakraThemeByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'blue';
    case 'MTNNG':
      return 'yellow';
    case 'AIRNG':
      return 'red';
    default:
      return 'blue';
  }
};

export const getTextColorByTheme = (theme: string) => {
  switch (theme) {
    case '#57AFE9':
      return '#1A3A6B';
    case '#ffcb05':
      return '#000';
    case '#F22121E5':
      return '#fff';
    default:
      return '#1A3A6B';
  }
};

export const getStrokeColorByPartner = () => {
  switch (partnerCode) {
    case 'MTNNG':
      return '#FFD700';
    case 'AIRNG':
      return '#FF0000';
    default:
      return '#57AFE9';
  }
};
export const getLogoByPartner = (isWhite?: boolean) => {
  switch (partnerCode) {
    case 'PNFNG':
      return isWhite ? pnfWhiteLogo : pnfLogo;
    case 'MTNNG':
      return mtnLogo;
    case 'AIRNG':
      return isWhite ? airtelWhiteLogo : airtelLogo;
    default:
      return isWhite ? pnfWhiteLogo : pnfLogo;
  }
};

export const getLayoutByPartner = () => {
  switch (partnerCode) {
    case 'MTNNG':
      return '#FFC32C0F';
    case 'AIRNG':
      return 'rgba(255, 0, 0, 0.1)';
    default:
      return 'rgba(87, 175, 233, 0.1)';
  }
};

export const getLandingImageByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return pnfLandingLogo;
    case 'MTNNG':
      return mtnLandingLogo;
    case 'AIRNG':
      return airtelLandingLogo;
    case 'PNFUK':
      return ukPnfLandingLogo;
    default:
      return pnfLandingLogo;
  }
};

export const getGradientByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'linear(180deg, #57AFE9 0%, #3E2CB1 100%)';
    case 'MTNNG':
      return 'linear(180deg, #ffcb05 0%, #ffcb05 100%)';
    case 'AIRNG':
      return 'linear-gradient(100deg, #b00, #8d0000), linear-gradient(100deg, #8aaebb, #89adba)';
    default:
      return 'linear(180deg, #57AFE9 0%, #3E2CB1 100%)';
  }
};

export const getValidPhoneByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return '';
    case 'MTNNG':
      return 'Please, input a valid MTN phone number';
    case 'AIRNG':
      return 'Please, input a valid Airtel phone number';
    default:
      return '';
  }
};

export const getTncByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/tnc/chosqdabh38g34q0o9g0-1719959817453.pdf';
    case 'MTNNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/tnc/r5fnjhh6569mv0c6a1o4-1719960077440.pdf';
    case 'AIRNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/tnc/i87t54yow89gvnd0pdcl-1719960126765.pdf';
    case 'PNFUK':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/tnc/buvwoa089p3ycgfshdkd-1724802904211.pdf';
    default:
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/tnc/chosqdabh38g34q0o9g0-1719959817453.pdf';
  }
};

export const getPrivacyByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/privacy/n9zre6cx805uthrwp732-1724840223502.pdf';
    case 'MTNNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/privacy/n9zre6cx805uthrwp732-1724840223502.pdf';
    case 'AIRNG':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/privacy/n9zre6cx805uthrwp732-1724840223502.pdf';
    case 'PNFUK':
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/privacy/vdek89dix09albowz6ll-1724840172051.pdf';
    default:
      return 'https://picknfix-staging.fra1.digitaloceanspaces.com/privacy/n9zre6cx805uthrwp732-1724840223502.pdf';
  }
};

export const getBtnThemeByPartner = () => {
  switch (partnerCode) {
    case 'PNFNG':
      return { color: '#fff', bg: '#0F61D6' };
    case 'MTNNG':
      return { color: 'black', bg: '#ffcb05' };
    case 'AIRNG':
      return { color: 'white', bg: '#F22121E5' };

    default:
      return { color: '#fff', bg: '#0F61D6' };
  }
};
