import { gql, useQuery } from '@apollo/client';
import { FC, ReactNode, createContext, useContext, useState } from 'react';

import { FullPageSpinner } from '@/components/full-page-spinner';
import { getErrorMessage } from '@/utils';
import { Text } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
}

const GET_PARTNER_BY_CODE = gql`
  query GetPartnerByCode{
    partner(where: { code: { _eq: ${process.env.NEXT_PUBLIC_PARTNER_CODE} } }) {
      code
      allow_postpaid_plan
      id
    }
  }
`;

const PartnerContext = createContext<{
  partner: { code: string; allow_postpaid_plan: boolean; id: string } | null;
}>({ partner: null });

const PartnerProvider: FC<Props> = ({ children }) => {
  const [partner, setPartner] = useState<{ code: string; allow_postpaid_plan: boolean; id: string } | null>(null);

  const { loading, error } = useQuery(GET_PARTNER_BY_CODE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setPartner(data.partner?.[0]);
    },
  });

  if (error) {
    return <Text>{getErrorMessage(error)} </Text>;
  }

  if (partner?.id) {
    return <PartnerContext.Provider value={{ partner }}>{children}</PartnerContext.Provider>;
  }

  return <FullPageSpinner />;
};

const usePartnerData = () => useContext(PartnerContext);

export { PartnerProvider, usePartnerData };
